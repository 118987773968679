import { Component } from '@angular/core';
import { CognitoService } from '../cognito.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {
  constructor(private authservice: CognitoService) {}

  ngOnInit(): void {
    console.log(`intializing HomeComponent`);
  }

  logOut() {
    this.authservice.logOut();
  }
}
