import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { AuthstatusComponent } from './authstatus/authstatus.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, AuthstatusComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'website';
  id_token: string = '';
  access_token: string = '';
  expires_in: string = '';
  token_type: string = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.id_token = params.get('id_token')!;
      this.access_token = params.get('access_token')!;
      this.expires_in = params.get('expires_in')!;
      this.token_type = params.get('token_type')!;
    });
  }
}
