import { Component, Input } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-authstatus',
  standalone: true,
  imports: [],
  templateUrl: './authstatus.component.html',
  styleUrl: './authstatus.component.css',
})
export class AuthstatusComponent {
  cognitoLoginUrl = environment.cognitoLoginUrl;
  cognitoLogoutUrl = environment.cognitoLogoutUrl;

  @Input() id_token: string = '';
  @Input() access_token: string = '';
  @Input() expires_in: string = '';
  @Input() token_type: string = '';

  constructor() {}
}
