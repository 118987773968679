<div class="main">
    <div class="newPassword">
        <div class="container">
            <h1>New Password Require</h1>

            <div class="newPasswordClass">
                <form>
                    <input type="password" placeholder="Current password" id="currentpassword" name="currentpassword"
                        ngModel #codeInput="ngModel" [(ngModel)]="currentpassword">
                    <input type="password" placeholder="New Password" id="newPassword" name="newPassword" ngModel
                        #passwordInput="ngModel" [(ngModel)]="newPassword">
                    <input type="password" placeholder="Confirm Password" id="confirmPassword" name="confirmPassword"
                        ngModel #confirmpasswordInput="ngModel" [(ngModel)]="confirmPassword">

                    <button (click)="confirmPasswordReset()" type="submit">RESET</button>
                </form>
            </div>

        </div>
    </div>
</div>