import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { CognitoService } from '../cognito.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [FormsModule],
  standalone: true,
})
export class LoginComponent {
  emailaddress: string = '';
  password: string = '';

  constructor(private authservice: CognitoService) {}

  onSignIn(form: NgForm) {
    if (form.valid) {
      this.authservice.login(this.emailaddress, this.password);
    }
  }
}
