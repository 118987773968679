import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  userPool: any;
  cognitoUser: any;
  username: string = '';

  constructor(private router: Router) {}

  // Login
  login(emailaddress: any, password: any) {
    let authenticationDetails = new AuthenticationDetails({
      Username: emailaddress,
      Password: password,
    });

    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };

    this.username = emailaddress;
    this.userPool = new CognitoUserPool(poolData);
    let userData = { Username: emailaddress, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result: any) => {
        this.router.navigate(['/home']);
        console.log('Success Results : ', result);
      },
      // First time login attempt
      newPasswordRequired: () => {
        this.router.navigate(['/newPasswordRequire']);
      },
      onFailure: (error: any) => {
        console.log('error', error);
      },
    });
  }

  // First time login attempt - New password require
  changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
  ) {
    if (newPassword === confirmPassword) {
      this.cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: () => {
            console.log('Reset Success');
            this.router.navigate(['/login']);
          },
          onFailure: () => {
            console.log('Reset Fail');
          },
        },
      );
    } else {
      console.log("Password didn't Match");
    }
  }

  // Logout
  logOut() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.cognitoUser = this.userPool.getCurrentUser();
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      this.router.navigate(['login']);
    }
  }
}
