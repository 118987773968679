import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CognitoService } from '../cognito.service';

@Component({
  selector: 'app-new-password-require',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './new-password-require.component.html',
  styleUrl: './new-password-require.component.css',
})
export class NewPasswordRequireComponent {
  currentpassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  constructor(private authService: CognitoService) {}

  confirmPasswordReset() {
    this.authService.changePassword(
      this.currentpassword,
      this.newPassword,
      this.confirmPassword,
    );
  }
}
