<div class="main">
    <div class="login">
        <div class="container">
            <h1>Login</h1>

            <div class="loginclass">
                <form (submit)="onSignIn(signInForm)" #signInForm="ngForm">
                    <input type="email" placeholder="email" id="email" name="email" ngModel #emailInput="ngModel"
                        [(ngModel)]="emailaddress">
                    <input type="password" placeholder="Password" id="password" name="password" ngModel
                        #passwordInput="ngModel" [(ngModel)]="password">

                    <button type="submit">LOGIN</button>
                </form>
            </div>

        </div>
    </div>
</div>